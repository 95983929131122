// DROPDOWN COMPONENT
// reuses the `o-dropdown` styles in a way that doesn't break BEM rules and is more modular
// TODO: deal with o-dropdown, which is in use in the app

/* stylelint-disable */

// disabling linting because dropdowns are very over-nested, and there's no quick or elegant way to change that
// TODO: retwrite and restructure dropdowns to be more modular

.c-dropdown {
  display: flex;
  position: relative;

  .c-dropdown__item {
    padding: 4px 8px;
    width: 100%;
  }

  .c-dropdown__item {
    // <button> overrides
    background-color: $light-white;
    border: 0;
    border-radius: 0;
    color: $d2-dark-gray;
    font-family: $base-font-family;
    font-size: $s1-font-size;
    height: auto;
    margin-bottom: auto; // skeleton <li> override
    text-align: left;
    text-transform: none;

    &:hover {
      background-color: $l3-light-gray;
      cursor: pointer;
      text-decoration: none; // link override
    }
  }

  .c-dropdown__icon {
    // layout that allows icons of differing widths to appear centered
    display: flex;
    font-size: $s2-font-size;
    justify-content: center;
    margin-right: 6px;
    width: 20px;
  }

  &.is-open {
    // TODO: remove layout styles and replace with `l-box` classes
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .o-hovertooltip[data-tooltip]:hover::after {
      // so corresponding tooltip doesn't appear beneath open dropdown
      display: none;
    }

    .c-dropdown__list {
      border-radius: 3px;
      box-shadow: 0 0 20px rgba(190, 190, 190, 0.2);
      display: flex;
      flex-direction: column;
      white-space: nowrap;

      &.c-dropdown__list--dark {
        // overrides for dark list
        background-color: $d1-dark-gray;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.6);
        color: $light-white;
        padding: 5px 0;

        .c-dropdown__item {
          // showrunner <button> overrides
          background-color: inherit;
          border: 0;
          border-radius: 0;
          color: $light-white;
          font-family: $base-font-family;
          font-size: $s1-font-size;
          height: auto;
          margin-bottom: auto; // skeleton <li> override
          padding: 4px 8px;
          text-align: left;
          text-transform: none;
          // unique styles
          width: 100%;

          &:hover {
            background-color: $base-dark-gray;
            color: $light-white;
            cursor: pointer;
          }
        }
      }

      .c-dropdown__item.c-dropdown__item--red {
        color: $error-color;

        &:hover {
          color: $error-color;
        }
      }
    }
  }

  .o-button {
    .o-button__label {
      display: inline;
    }
  }

  .c-dropdown__list {
    display: none;
    list-style-type: none;
    position: absolute;
    top: 100%;
    z-index: map-get($zindex, 'dropdown');
  }
}

// toolbar dropdowns

.c-dropdown.is-open {
  .c-dropdown__item {
    padding: 6px 12px;
  }
}

/* stylelint-enable */
