
          @use '@scss/all' as wallaby;
        
.settingsPage {
  display: grid;
  grid-template-columns: minmax(200px, 25%) minmax(500px, 75%);
  margin: 0 auto;
  max-width: 1150px;
  padding: 30px 20px;
}
