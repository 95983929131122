
          @use '@scss/all' as wallaby;
        
// how tall are the short ticks on ruler
$short-tick-height: 5px;
// ditto the long ticks
$long-tick-height: calc($short-tick-height * 2);
// the numeric labels rise above the bottoms of the long ticks
// by this much
$label-offset: calc($short-tick-height * 2);

// the "breathing room".  Note that it's important to build padding into
// the ruler itself and not a wrapper because we need to anchor it to the
// top of the editor viewport and scale it.
$top-padding: 15px;
$bottom-padding: 10px;

$height: calc(
  $top-padding + $long-tick-height + $bottom-padding + $label-offset
);

.marginRuler {
  align-content: flex-start;
  align-items: flex-start;
  background-color: wallaby.$theme-gray-2;
  display: flex;
  flex-wrap: nowrap;
  height: $height;
  padding-top: $top-padding;
  position: relative;
}

.tick {
  border-left: 1px wallaby.$theme-gray-6 solid;
  height: $short-tick-height;
  position: relative;
  width: var(--tick-width);
}

.tick:last-of-type {
  border-right: 1px wallaby.$theme-gray-6 solid;
}

.majorTick {
  border-color: wallaby.$theme-gray-6;
  height: $long-tick-height;
  top: 0;
}

.tickLabel {
  bottom: $label-offset;
  color: wallaby.$theme-gray-6;
  font-size: 12px;
  font-weight: bold;
  left: -4px;
  position: absolute;
  user-select: none;
}

.marginRulerWrapper {
  display: flex;
  justify-content: center;
  min-height: calc($height * var(--ruler-zoom));
  position: sticky;
  top: 0;
  z-index: 2;

  .marginRuler {
    transform: scale(var(--ruler-zoom));
    transform-origin: top center;
  }
}

.marginRulerWrapper.leftAligned {
  justify-content: flex-start;

  .marginRuler {
    transform-origin: top left;
  }
}
