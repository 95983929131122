
          @use '@scss/all' as wallaby;
        
.slotSelect_stack {
  width: 180px;
}

.slotSelect_input {
  font-size: 14px;
}

.slotSelect_input___inactive {
  background: var(--mantine-color-gray-2);
  color: var(--mantine-color-gray-6);
}
