
          @use '@scss/all' as wallaby;
        
.control {
  border-radius: 3px;
}

.label {
  font-size: 14px;
}

.indicator {
  background-color: #fff;
}

.root {
  background-color: wallaby.$theme-gray-3;
  padding: 2px 4px;
}
