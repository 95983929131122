
          @use '@scss/all' as wallaby;
        
.createThreadButton {
  // TODO: consider building into variant of action icon
  box-shadow: 0 0 10px rgb(171 171 171 / 0.25);
  pointer-events: all;
  position: absolute;

  &.paged {
    right: 0;
    transform: translate(calc(100% + 5px), -50%);
  }

  &.pageless {
    left: 8px;
    transform: translateY(-50%);
  }
}
