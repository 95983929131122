
          @use '@scss/all' as wallaby;
        
.swatch {
  // mantine swatch dupe
  background-image: linear-gradient(
      45deg,
      var(--alpha-overlay-color) 25%,
      transparent 25%
    ),
    linear-gradient(-45deg, var(--alpha-overlay-color) 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, var(--alpha-overlay-color) 75%),
    linear-gradient(
      -45deg,
      var(--alpha-overlay-bg) 75%,
      var(--alpha-overlay-color) 75%
    );
  box-shadow:
    rgba(0, 0, 0, 0.1) 0 0 0 1px inset,
    rgb(0, 0, 0, 0.15) 0 0 1px inset;
  color: #fff;
}

.swatch___active {
  border: 3px solid wallaby.$theme-violet-5;
  box-shadow:
    rgba(255, 255, 255, 1) 0 0 0 1px inset,
    rgb(255, 255, 255, 0.15) 0 0 1px inset;
}
