@font-face {
  font-family: 'Courier Prime Sans';
  src: url('https://fontawesome-kits.s3.us-east-1.amazonaws.com/CourierPrimeSans.ttf')
    format('truetype');
}

@font-face {
  font-family: 'Courier Prime Sans';
  font-weight: bold;
  src: url('https://fontawesome-kits.s3.us-east-1.amazonaws.com/CourierPrimeSansBold.ttf')
    format('truetype');
}

@font-face {
  font-family: 'Courier Prime Sans';
  font-style: italic;
  src: url('https://fontawesome-kits.s3.us-east-1.amazonaws.com/CourierPrimeSansItalic.ttf')
    format('truetype');
}

@font-face {
  font-family: 'Courier Prime Sans';
  font-style: italic;
  font-weight: bold;
  src: url('https://fontawesome-kits.s3.us-east-1.amazonaws.com/CourierPrimeSansBoldItalic.ttf')
    format('truetype');
}
