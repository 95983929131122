
          @use '@scss/all' as wallaby;
        
.wrapper {
  height: 100%;
  padding-bottom: 5px;
  padding-top: 5px;

  // this one is purely for My history
  // virtual lists control scrolling themselves.
  &.wrapper__overflow {
    overflow-y: auto;
  }
}
