
          @use '@scss/all' as wallaby;
        
.commentParts_attributionName {
  word-break: break-all;
}

.commentParts_snippet {
  word-break: break-word;
}

.commentParts_commentText {
  // TODO: do we want pre-wrap here? pre-line? normal?
  white-space: pre-wrap;
  word-break: break-word;
}

// The parent may set the --comment-action-button-opacity css var.
// If it's not set, we fall back to 1.
// (the feed's buttons are always opaque, the panel buttons are not)
.commentParts_action {
  font-size: 14px;
  opacity: var(--comment-action-button-opacity, 1);
}

.commentParts_input___saving {
  border-color: wallaby.$theme-gray-3;
}
