
          @use '@scss/all' as wallaby;
        
/* stylelint-disable selector-max-compound-selectors */
.pmHostWrapper {
  // this position: relative is EXTREMELY important because of the fragile way
  // we calculate positions relative to the editor's offsetTop (e.g. comment icons
  // next to blocks.
  position: relative;
  width: 100%;

  // special way to hide the editor while still having it do all it's layout, etc.
  // if we try { display: none } we get errors with prosemirror positioning, etc
  &.safelyHidden :global(.c-editor) {
    opacity: 0;
    user-select: none;
  }
}

:global {
  .ProseMirror {
    font-size: wallaby.$script-font-size;
    line-height: wallaby.$script-line-height;
    white-space: pre-wrap;

    .ensure-newline-target {
      cursor: text;
      height: 1in;
      width: 100%;

      &.visible {
        border: 2px wallaby.$theme-gray-3 dashed;
      }
    }
  }
}

.disconnected :global {
  .ProseMirror {
    color: wallaby.$d1-mid-gray;
    cursor: default;

    .o-block-element {
      color: wallaby.$d1-mid-gray;
    }

    // When disconnected, the element numbers plugin
    // is still rendering inputs, but pseudoelement numbers are
    // also visible. This suppresses the pesky editable ones
    .o-block-element .element-number-input {
      display: none;
    }
  }
}

// base styling for the blurred cursor sets it to display: none
.blurredCursor {
  background-color: #00000040;
  display: none;
  height: calc(1.4 * 1em);
  pointer-events: none;
  position: absolute;
  transform: translate(-1.5px, calc(-0.2 * 1em));
  width: 3px;
  z-index: 1;
}

// if the .pmHostWrapper has .emptySelection and does not have
// focus within, show the blurred cursor
.pmHostWrapper.emptySelection:not(:focus-within) .blurredCursor {
  display: unset;
}

:root {
  --prose-selection-color: #34343430;
}

// When the focus is within the editor, hide the .blurredCursor
// and make the prose-selectiion blue
.pmHostWrapper:focus-within {
  --prose-selection-color: #2387fa30;
}

.pmHostWrapper.isCreatingComment {
  --prose-selection-color: #ffca52;
}

// suppress the built-in contenteditable :selection styles
// and instead style the .prose-selection decoration
:global {
  .prose-selection {
    background-color: var(--prose-selection-color) !important;
  }

  // avoid suppressing the built-in background for the
  // editable element number widget
  .ProseMirror:not(.is-static) {
    :not(.element-number-input)::selection {
      background-color: transparent;
    }
  }
}

// if we're creating a new comment mark, we want the decoration to have a background color
// and to suppress the normal ::selection background. After we de-flag ?debug=selection this
// logic can adjust
.isCreatingComment :global {
  .ProseMirror {
    ::selection {
      background-color: unset;
    }

    .prose-unsaved-comment {
      background-color: #ffca52;
    }
  }
}

:global {
  .ProseMirror {
    [data-margin-slot] {
      position: absolute;
    }

    [data-margin-slot^='top-'] {
      top: 0.5in;
    }

    [data-margin-slot^='bottom-'] {
      top: 10.3in;
    }

    [data-margin-slot$='-left'] {
      left: 1.5in;
    }

    [data-margin-slot$='-right'] {
      right: 1in;
      text-align: right;
    }

    [data-margin-slot$='-center'] {
      left: 1.5in;
      right: 1in;
      text-align: center;
    }

    // The indentation styles plugin sets values for these for
    // ink scripts. This gives a hanging indent when wrapping ink text. If
    // the var isn't set (e.g. plugin doesn't run) then these set padding-left
    // and text-indent to 0.
    .o-block-element {
      padding-left: var(--wrap-indent, 0);
      text-indent: calc(-1 * var(--wrap-indent, 0));
    }

    // horizontal position is set in the pageless/print wrapper
    .o-block-element .comment-thread-bubble {
      color: wallaby.$theme-gray-6;
      cursor: pointer;
      font-size: 15px;
      position: absolute;
      top: 0;
      user-select: none;
    }

    // horizontal position is set in the pageless/print wrapper
    .o-dual-dialogue .element-number-input,
    .o-block-element .element-number-input {
      border: 0;
      font-size: 12pt;
      height: 1em;
      line-height: 1;
      min-width: 1em;
      padding: 0;
      position: absolute;
      text-align: end;
      width: 48px;

      // kludgey non-mantine legacy override
      &:focus {
        border: 0;
      }
    }
  }
}

// debug styles for layout-- using outlines and pseudo-elements
// to not affect positoning.
$page-margin: 1px wallaby.$theme-green-6 dashed;
$block-margin: 1px wallaby.$theme-gray-6 dashed;

.showLayoutLines :global {
  .c-editor__gutterleft,
  .c-editor__gutterright {
    z-index: 1;
  }

  .top-of-page,
  .bottom-of-page {
    z-index: 2;
  }

  .c-editor__gutterleft,
  .c-editor__gutterright,
  .top-of-page {
    &::after {
      content: '';
      inset: 0;
      position: absolute;
    }
  }

  .c-editor__gutterleft::after {
    border-right: $page-margin;
  }

  .c-editor__gutterright::after {
    border-left: $page-margin;
  }

  .top-of-page::after {
    border-bottom: $page-margin;
  }

  // bottom of page has variable size to fill extra area, so
  // just stick the pseudo-element to the bottom
  .bottom-of-page::after {
    border-top: $page-margin;
    bottom: 0;
    content: '';
    height: var(--page-bottom-padding);
    left: 0;
    position: absolute;
    right: 0;
  }

  .o-page > .o-block-element,
  .o-dual-dialogue .o-block-element {
    outline: $block-margin;
  }

  .o-dual-dialogue__column {
    background-color: wallaby.$theme-gray-2;
  }

  .element-number-input {
    background-color: wallaby.$theme-blue-1;
    z-index: 10;
  }
}
