
          @use '@scss/all' as wallaby;
        
// Styles defined on the root here should define variants and overrides of data
// attribute selectors
.root {
  // An override for all full-screen modals to allow for a custom x an y offset
  &[data-full-screen] {
    .content {
      --modal-content-max-height: initial;
    }

    .inner {
      --modal-inner-y-offset: initial;
      --modal-inner-x-offset: initial;
    }
  }

  // The "compact" variant removes the vertical padding from the header
  &[data-variant='compact'],
  // The "standard" variant extends these styles
  &[data-variant='standard'] {
    .header {
      min-height: 41px;
      padding-bottom: 0;
      padding-inline-end: calc(
        var(--mb-padding, var(--mantine-spacing-md)) - 6px
      );
      padding-top: 0;
    }

    &[data-full-screen] {
      --mb-padding: 0;
      --modal-header-border-width: 0 0 1px 0;

      .header {
        --mb-padding: 12px;
      }
    }
  }

  // The classic scripto modal with a thin, grey header
  &[data-variant='standard'] {
    --modal-header-background: var(--mantine-color-gray-1);
    --modal-header-border-width: 1px;
    --modal-radius: 3px;
    --mb-padding: 15px;

    .header {
      margin-bottom: var(--mb-padding);
      min-height: 35px;
      // the close button should have a fixed distance from the edge:
      padding-inline-end: 5px;
    }

    .title {
      font-weight: 700;
    }
  }

  // A modifier of the modal to allow for a fixed-height body, preventing the
  // default scrolling behavior
  &[data-fixed-height] {
    .content {
      display: flex;
      flex-direction: column;
    }

    .body {
      flex: 1;
      overflow: hidden;
    }
  }
}

// The remaining selectors should set up variable overrides for Mantine's modal
// styles that the variants can then define

.header {
  background-color: var(--modal-header-background, --mantine-color-body);
  border-color: var(--mantine-color-gray-3);
  border-style: solid;
  border-width: var(--modal-header-border-width, 0);
}
