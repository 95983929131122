
          @use '@scss/all' as wallaby;
        
:global {
  // The top of the Popover has a colored header, so we want any dropdowns
  // positioned below the target to have a colored arrow as well
  [data-position^='bottom'] > .mantine-Popover-arrow {
    background-color: wallaby.$theme-violet-0;
  }
}

.announcementPopover_header {
  background-color: wallaby.$theme-violet-0;
  border-top-left-radius: var(--popover-radius, var(--mantine-radius-default));
  border-top-right-radius: var(--popover-radius, var(--mantine-radius-default));
}

.announcementPopover_button:focus-visible {
  outline: 2px solid wallaby.$theme-violet-4;
}
