
          @use '@scss/all' as wallaby;
        
.commentsPanel {
  border-left: 5px wallaby.$theme-gray-3 solid;
  height: 100%;
}

.commentsPanel_header {
  border-bottom: 1px wallaby.$theme-gray-3 solid;
  padding: 0 8px 5px 10px;
}

.commentsPanel_close {
  border-radius: 3px;
  padding: 5px;

  &:hover {
    background-color: wallaby.$theme-gray-3;
  }
}

.commentsList {
  margin: 10px;
}
