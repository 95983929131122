
          @use '@scss/all' as wallaby;
        
/* stylelint-disable selector-max-compound-selectors */
/* stylelint-disable selector-no-qualifying-type */
$left-gutter: 50px;
$right-gutter: 77px;

// something like this:   - - - - PAGE BREAK - - - -
// with em-dashes and whitespace that doesn't collapse in css
$nbsp: '\00a0';
$mdash: '\2014';
$page-break-line: $mdash $mdash $mdash $mdash $mdash $mdash;
$page-break-spacer: $nbsp $nbsp;
$page-break-content: $page-break-line $page-break-spacer 'PAGE BREAK'
  $page-break-spacer $page-break-line;

.pagelessScroller {
  display: flex;
  height: 100%;
  overflow-y: auto;
  position: relative;
}

.pagelessContent {
  height: fit-content;
  overflow: hidden;
  position: absolute;
  transform: scale(var(--zoom-level));
  transform-origin: top left;
  width: calc(100% / var(--zoom-level));
}

.pagelessContent.noWrap {
  width: unset;

  :global(.o-block-element) {
    white-space: pre;
  }
}

.pagelessContent :global {
  .ProseMirror {
    // put background on .ProseMirror (the entire document) instead of per-page
    background-color: wallaby.$light-white;
    min-height: calc(var(--prose-editor-height) / var(--zoom-level));
    min-width: calc(var(--prose-editor-width) / var(--zoom-level));
    // pad the top and botttom of the entire script. The bottom padding is big enough
    // to fit a multi-line ink compile error tooltip
    padding: 30px 0 90px;
  }

  .pagebreak-hide-label {
    display: none;
  }

  .c-editor,
  .c-editor-banner {
    min-width: 250px;
    width: 100%;
  }

  .c-editor__gutterright {
    margin-left: -$right-gutter;
    pointer-events: none;
    width: $right-gutter;
  }

  .o-page {
    background: transparent;
    min-height: unset;
    padding-bottom: 0;
    padding-left: $left-gutter;
    padding-right: $right-gutter;
    padding-top: 0;
    width: 100%;
  }

  // remove gaps between pages
  .o-page + .o-page {
    margin-top: unset;
  }

  // hide all page numbers and margin decorations
  [data-margins] {
    display: none;
  }

  .o-block-element {
    position: relative;
    width: unset;
  }

  /*
      The left margin for 2nd level blocks gets special handling.

      - We reclaim excessive whitespace based on the script format's left-most
        configured block style. E.g. If EVERY block is indented 1"
        or more, we get rid of 1" from all blocks

      - if a single block is overridden so that it goes to the left of the left-most
        script-configured value, we set the margin-left to 0px. instead of the negative
        value it would have had

        NOTE: using 0px instead of 0 for the min value is required for the max() function
        to operate properly
  */
  .o-page > .o-block-element {
    --reclaimed-block-margin-left: max(
      calc(var(--current-block-margin-left) - var(--min-left-margin-script)),
      0px
    );

    margin-left: var(--reclaimed-block-margin-left);
  }

  .ProseMirror:not(.is-static):not(.is-screenplay)
    .o-block-element.is-untimed::before {
    left: calc(100% + 2px);
  }

  .o-block-element .comment-thread-bubble {
    right: -55px;
  }

  .ProseMirror.is-static [data-element-number]::before,
  .o-block-element > .element-number-input,
  .o-dual-dialogue > .element-number-input {
    left: calc(-1 * var(--reclaimed-block-margin-left));
    margin-left: -50px;
  }

  // Dual dialogue gets a little messy in pageless without
  // some special treatment for the widths of the columns
  .o-dual-dialogue {
    gap: 40px;

    .o-dual-dialogue__column {
      flex: 1;
    }
  }
}

@mixin page-break-decoration {
  color: wallaby.$theme-gray-5;
  content: $page-break-content;
  display: inline-block;
  font-family: 'Lato', sans-serif;
  font-size: 11px;
  font-weight: bold;
  overflow: hidden;
  padding: 10px 0;
  pointer-events: none;
  text-align: center;
  text-overflow: clip;
  white-space: nowrap;
  width: 100%;
}

// show manual page break decorations at the top of
// non-dynamic pages (except the first)
.pagelessContent.structuralPagination :global {
  .o-page:not(:first-of-type):not(.o-page-dynamic) {
    &::before {
      @include page-break-decoration();
    }

    // clear any extra space above
    > .o-block-element:first-of-type,
    > .o-dual-dialogue:first-of-type {
      margin-top: 0;
    }
  }
}

.pagelessContent.inlinePagination :global {
  .top-of-page.manual-break {
    display: inline-block;
    width: 100%;

    // suppress the top-margin for the first block following a manual
    // page break (the decoration gives enough padding)
    + .o-block-element {
      margin-top: 0;
    }

    &::after {
      @include page-break-decoration();
      display: inline-block;
    }
  }
}
