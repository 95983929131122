
          @use '@scss/all' as wallaby;
        
// very similar to the DotDotDot menu, but with slightly more padding
.createMenu_target {
  align-items: center;
  color: wallaby.$theme-gray-8;
  display: flex;
  height: 100%;
  opacity: 0.5;
  padding: 0 4px;
  right: 0;

  // on hover or if open, show at full opacity
  &[data-expanded],
  &:hover {
    opacity: 1;
  }
}
