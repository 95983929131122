
          @use '@scss/all' as wallaby;
        
.dropTargetWrapper {
  height: 100%;
  width: 100%;

  :global {
    // pseudo elements above and below. A style is set in the code
    // to make one visible during script dragover
    .ag-row::before,
    .ag-row::after {
      border: 0 wallaby.$theme-violet-5 solid;
      content: '';
      left: 0;
      position: absolute;
      width: 100%;
      z-index: 4;
    }

    .ag-row::before {
      top: -2px;
    }

    // first row gets masked under header if we don't push down a little
    .ag-row:first-of-type::before {
      top: 0;
    }

    .ag-row::after {
      bottom: -1px;
    }
  }
}
