
          @use '@scss/all' as wallaby;
        
// When we have an input with invalid values, we don't want the text
// or placeholder to be red, just the outline & error message
.input {
  font-family: 'Lato', sans-serif;

  &[data-error='true'] {
    color: 'inherit';

    &::placeholder {
      color: var(--mantine-color-gray-5);
      opacity: 'inherit';
    }
  }

  &[data-dirty='true'] {
    background-color: var(--mantine-color-violet-0);
  }
}

.numberInput,
.textInput {
  font-family: 'Lato', sans-serif;
}

.numberInput {
  &[data-error='true'] {
    border: 1px solid #f00;
  }
}

.root:has([data-dirty='true']) .numberInputControl {
  @media (hover: hover) {
    &:hover {
      background-color: var(--mantine-color-violet-1);
    }
  }

  @media (hover: none) {
    &:active {
      background-color: var(--mantine-color-violet-1);
    }
  }
}
