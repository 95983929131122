
          @use '@scss/all' as wallaby;
        
$thumb-color: wallaby.$theme-dark-9;
$disabled-thumb-color: wallaby.$theme-gray-6;
$active-thumb-color: wallaby.$theme-violet-5;
$background-color: #fff;
$boundary-color: wallaby.$theme-gray-4;

.track {
  background-color: $background-color;
  background-image: linear-gradient(
    to right,
    $boundary-color,
    $boundary-color var(--min-left-margin),
    transparent var(--min-left-margin),
    transparent var(--max-right-margin),
    $boundary-color var(--max-right-margin)
  );
  height: 10px;
  position: absolute;
}

.thumb {
  color: $thumb-color;
  font-size: 18px;
  margin-left: 0;
  position: absolute;
  top: -7px;
  transform: translateX(-50%);
  z-index: 2;
}

.thumb:not(.disabledThumb):hover,
.activeThumb {
  color: $active-thumb-color;
  cursor: ew-resize;
}

.disabledThumb {
  color: $disabled-thumb-color;
}

.activeThumb::after {
  border-left: 1px $active-thumb-color solid;
  content: ' ';
  display: inline-block;
  height: 100vh;
  position: absolute;
  top: 10px;
  transform: translateX(-50%);
  width: 100%;
}
