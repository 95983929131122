// buttons

button:hover {
  // preserves expected cursor behavior for buttons that aren't styled like buttons
  // e.g. dropdown items, comment indicators
  cursor: pointer;
}

.o-button {
  align-items: center;
  background-color: $light-white;
  border: 1px solid $d1-light-gray;
  border-radius: 3px;
  display: flex;
  font-family: $base-font-family;
  font-size: $s1-font-size;
  font-weight: 500;
  padding: 6px 12px;
  white-space: nowrap;

  &:hover,
  &:focus {
    border: 1px solid $d3-purple;
    color: $d3-purple;
    cursor: pointer; // for non-`button`s styled as buttons
    text-decoration: none; // for links styled as buttons
  }

  &.is-small {
    border-radius: 2px;
    font-size: $s1-font-size;
    padding: 2px 6px;
  }

  // primary

  &.o-button--primary {
    background-color: $d1-purple;
    border: 1px solid darken($d1-purple, 5%);
    color: $light-white;

    &:hover,
    &:focus {
      background-color: $d4-purple;
      border: 1px solid $d4-purple;
    }
  }

  // button with an icon to the left of the label text
  // (as opposed to a dropdown or close button)

  &.o-button--iconic {
    &:hover {
      .o-button__icon--dark {
        color: $d2-dark-gray;
      }
    }
  }

  // useful for dark backgrounds,
  // particularly for iconic buttons

  &.o-button--inverted {
    background-color: transparent;
    border: 0;
    color: $d3-light-gray;

    &:hover {
      color: $light-white;
    }
  }
}
