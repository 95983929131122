
          @use '@scss/all' as wallaby;
        
.select_option {
  font-size: 16px;

  // match :hover state and keyboard focus state for pending selection
  &[data-combobox-selected='true'] {
    background-color: var(--mantine-color-gray-0);
    color: var(--mantine-color-dark);
  }
}

.select_input___selected {
  background-color: wallaby.$theme-violet-0;
}
