// styles for user selections decorated with a mark should be honored
// on screen and in pdf exports (except when monochrome pref is set)
.scripto-background-color {
  background-color: var(
    --monochrome-background-color,
    var(--background-color, transparent)
  );
}

.scripto-text-color {
  color: var(--monochrome-text-color, var(--text-color, inherit));
}
