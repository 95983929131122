
          @use '@scss/all' as wallaby;
        
.resetButton[data-variant='outline'] {
  border: 1px solid wallaby.$theme-dark-9;
  color: wallaby.$theme-dark-9;
  height: 33px;
  min-width: unset;
  padding: 6px 8px;

  &:disabled[data-disabled='true'] {
    border: 1px solid wallaby.$theme-gray-3;
    color: wallaby.$theme-gray-3;
  }
}
