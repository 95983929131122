// general icon styles

.o-icon {
  color: $d3-light-gray;

  &.o-icon--custom i {
    font-family: 'icomoon';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: 1;
    text-transform: none;
  }
}

// custom icon font
// generated by icomoon.io

@font-face {
  font-display: block;
  font-family: 'icomoon';
  font-style: normal;
  font-weight: normal;
  src: url('../../../assets/fonts/icomoon.eot');
  src:
    url('../../../assets/fonts/icomoon.eot') format('embedded-opentype'),
    url('../../../assets/fonts/icomoon.ttf') format('truetype'),
    url('../../../assets/fonts/icomoon.woff') format('woff'),
    url('../../../assets/fonts/icomoon.svg') format('svg');
}

i {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-exclude-timing::before {
  content: '\e911';
}

.icon-caps::before {
  content: '\e910';
}

.icon-drag::before {
  content: '\e90e';
}

.icon-new-act::before {
  content: '\e900';
}

.icon-end-of-act::before {
  content: '\e901';
}

.icon-slug::before {
  content: '\e902';
}

.icon-general::before {
  content: '\e90b';
}

.icon-action::before {
  content: '\e903';
}

.icon-character::before {
  content: '\e905';
}

.icon-dialogue::before {
  content: '\e906';
}

.icon-dual-dialogue::before {
  content: '\e907';
}

.icon-bracket::before {
  content: '\e904';
}

.icon-inline-comment::before {
  content: '\e908';
}

.icon-page-break::before {
  content: '\e909';
}

.icon-parenthetical::before {
  content: '\e90a';
}

.icon-scene-heading::before {
  content: '\e90c';
}

.icon-transition::before {
  content: '\e90d';
}
