
          @use '@scss/all' as wallaby;
        
.elementCustom_tabPanel {
  background-color: #f6f6f6;
  min-width: 340px;
}

.elementCustom_tab[data-disabled='true'] {
  cursor: not-allowed;
}

.menuItem[data-active='false'] {
  background-color: #fff;

  &:hover,
  &:focus-visible {
    background-color: #eee;
  }
}

.menuItem[data-hovered='true'][data-active='true'] {
  background-color: wallaby.$theme-violet-6;

  &:hover,
  &:focus-visible {
    background-color: wallaby.$theme-violet-7;
  }
}
