
          @use '@scss/all' as wallaby;
        
.commentHistoryFeed_wrapper {
  background-color: wallaby.$theme-gray-0;
  border: 1px solid wallaby.$theme-gray-3;
  border-bottom: 0;
  overflow: hidden;
}

.commentHistoryFeed_topBar {
  background-color: wallaby.$theme-gray-2;
  border-bottom: 1px solid wallaby.$theme-gray-3;
  height: 38px;
  padding-left: 10px;
  padding-right: 5px;
}

.commentHistoryFeed_scrollArea {
  margin: 15px;
}
