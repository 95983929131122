
          @use '@scss/all' as wallaby;
        
/* stylelint-disable selector-max-compound-selectors */
$scaled-editor-width: calc(var(--unscaled-editor-width) * var(--zoom-level));
$page-number-bg-color: wallaby.$theme-gray-1;
$page-number-color: wallaby.$theme-gray-6;
$gap-between-pages: 0.5in;

@mixin page-number-decoration {
  background-color: $page-number-bg-color;
  border-radius: 25px;
  color: $page-number-color;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  font-weight: bold;
  margin: 10px;
  padding: 4px 16px;
  position: absolute;
  right: 0;
  top: 0;
}

.printPreviewWrapper {
  counter-reset: pm-current-page;
  display: block;
  height: 100%;
  overflow: hidden;
  width: 100%;

  // adjust the exclude-from timing icon position but ONLY in the live editor
  :global .ProseMirror:not(.is-static):not(.is-screenplay) {
    // put the exclude from timing icon at the left of the right gutter (then
    // shift it a few px to the right
    .o-block-element.is-untimed::before {
      left: calc(var(--block-start-to-page-right) - var(--page-right-padding));
      margin-left: 4px;
    }
  }

  :global .o-page {
    .o-block-element .comment-thread-bubble {
      left: calc(var(--block-start-to-page-right) + 5px);
    }

    .o-block-element > .element-number-input,
    .o-dual-dialogue > .element-number-input {
      // make it transparent in case it's overlapping some text
      background-color: transparent;
      // this is set in _elements.scss
      left: var(--element-number-left);
    }
  }
}

.scriptScrollWrapper {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;

  .scaledScriptWrapper {
    padding: 25px 50px;
    transform: scale(var(--zoom-level));
    transform-origin: top center;
  }
}

/*
  We need totally different techniques for handling
  the layout depending on whether horizontal scrolling
  is required or not
*/
.scriptScrollWrapper.noScroll {
  align-items: center;
  display: flex;
  flex-direction: column;
}

// When scrolling, don't center the content and
// set the zoom origin to the top left
.scriptScrollWrapper.scroll {
  overflow-x: auto;

  // this makes the scroll area wide enough for the script
  // content
  .scriptCenteringBox {
    width: var(--unscaled-editor-width);
  }

  // we have the opposite problem when the zoom level is < 1
  // Then, we wind up with extra space on the right that
  // this corrects. However, this doesn't work for the margin
  // ruler so maybe it's not worth it?
  &.shrunk .scriptCenteringBox {
    width: $scaled-editor-width;
  }

  // scale the script from the top-left
  .scaledScriptWrapper {
    transform-origin: top left;
  }
}

// For structural pagination, we make every .o-page look like a piece of paper
// with padding on all sides
.printPreviewWrapper.structuralPagination :global .o-page {
  // Create an 11" tall background on the page so that if the page text gets
  // too long, it will overflow the background just like it would the printed page
  background: linear-gradient(
    to bottom,
    wallaby.$light-white 11in,
    0%,
    transparent calc(100% - 11in)
  );
  // each .o-page increments the page number
  counter-increment: pm-current-page;

  // using min-height to allow overflow between repagination
  min-height: 11in;

  padding-bottom: var(--page-bottom-padding);
  padding-left: var(--page-left-padding);
  padding-right: var(--page-right-padding);
  padding-top: var(--page-top-padding);

  // we need this so on-screen page numbers position themselves
  // relative to each page
  position: relative;

  + .o-page {
    margin-top: 0.5in;
  }

  // The first level 2 block on a page has no space above.
  // the > selector ensures this is a level 2 block, the
  // :first-child limits it to the first child of an o-page
  > .o-block-element:first-child,
  > .o-dual-dialogue:first-child {
    margin-top: 0;
  }

  // pseudo-element for the page numbers
  &::before {
    @include page-number-decoration();
    content: counter(pm-current-page) var(--total-page-count, '');
  }
}

// For inline pagination, there's a single .o-page element for the entire
// script. We use the .o-page for left & right padding and the white background
// TK: we will create widget decorations for tops and bottoms of pages to provide the
// top and bottom page effects and to house the on-screen page numbers
$page-bg: #fff;
$app-bg: #eee;

.printPreviewWrapper.inlinePagination :global .o-page {
  background-color: wallaby.$light-white;
  // the top and bottom margins are handled by the prosemirror
  // widget decorations (.top-of-page and .bottom-of-page)
  padding-left: var(--page-left-padding);
  padding-right: var(--page-right-padding);

  // temporary style to decorate the non-first .o-page
  // these should get ripped out by the depaginator plugin
  // so if we're seeing them, that's a problem
  &:not(:first-of-type) {
    border: 2px wallaby.$theme-red-5 dashed;
  }

  .top-of-page,
  .bottom-of-page {
    // Negate the padding that the print preview wrapper set
    // so that the decorations span the full page width
    margin-left: calc(-1 * var(--page-left-padding));
    margin-right: calc(-1 * var(--page-right-padding));
    // we need this to position pseudoelements
    position: relative;
  }

  // this gives the 1in margin at the top of the page and it's
  // got a pseudoelement for the page number
  .top-of-page {
    height: var(--page-top-padding);

    &::before {
      @include page-number-decoration();
      content: var(--page-number) var(--total-page-count, '');
    }
  }

  // suppress the top-margin for the first block on a page
  .top-of-page + .o-block-element,
  .top-of-page + .o-dual-dialogue {
    margin-top: 0;
  }

  .bottom-of-page {
    // the gap between pages is just a bottom border on on this element
    border-bottom: $gap-between-pages $app-bg solid;
    // height is page-shortfall + bottom-page-margin + gap-between-pages
    height: calc(
      var(--page-height-shortfall, 0) + var(--page-bottom-padding) +
        $gap-between-pages
    );
  }

  // if the bottom of page is right after an ensure-newline-target, we can omit
  // the page-bottom-margin because the ensure-newline-target is already giving
  // us that vertical space
  .ensure-newline-target + .bottom-of-page {
    height: calc(var(--page-height-shortfall, 0) + $gap-between-pages);
  }
}
