
          @use '@scss/all' as wallaby;
        
.selectedSideIcon {
  --height: 16px;
  --width: 20px;
  --border-width: 2px;
  --half-width: calc(var(--width) / 2 - var(--border-width) / 2);

  flex-shrink: 0;
  margin-right: 6px;
}

.selectedSideIcon.large {
  --height: 24px;
  --width: 30px;
  --border-width: 3px;
}

.selectedSideIcon {
  border: var(--border-width) var(--border-color) solid;
  height: var(--height);
  position: relative;
  width: var(--width);

  .inner {
    background-color: var(--background-color);
    border-color: var(--border-color);
    border-style: solid;
    border-width: 0;
    bottom: 0;
    position: absolute;
    top: 0;
    width: var(--half-width);
  }
}

.selectedSideIcon.left {
  --background-color: #ffc9c9;
  --border-color: #ec0606;

  .inner {
    border-right-width: var(--border-width);
    left: 0;
  }
}

.selectedSideIcon.right {
  --background-color: #b2f2bb;
  --border-color: #2f9e44;

  .inner {
    border-left-width: var(--border-width);
    right: 0;
  }
}
