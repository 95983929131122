
          @use '@scss/all' as wallaby;
        
.toolbar {
  border-bottom: 1px wallaby.$theme-gray-3 solid;
  flex-wrap: nowrap;
  padding: 0 6px;
}

.toolbar_bottom {
  border-bottom: 0;
  border-top: 1px wallaby.$theme-gray-3 solid;
}

.toolbar___inner {
  flex-wrap: nowrap;
  height: var(--toolbar-height);
  white-space: nowrap;
}

.toolbar___innerBottom {
  height: 30px;
}

.toolbar___innerRight {
  flex: 1;
}

.toolbar___divider {
  margin: 0;
}
