// Empty block placeholders
// --------------------
// The editor adds .is-empty-block and .is-empty-doc classes to blocks
// as appropriate.
//
// RULES
// 1. When the doc is completely empty, we show placeholder text for each block type
//
// 2. When the doc is completely empty and the block type is one of the default block types
//    (dialogue, character or sceneHeading) we append in some extra text to explain about changing
//    block types
//
// 3. When there is an empty block in a non-empty doc and the block type is new_act,
//    end_of_act, sceneHeading or slug, we we use a placeholder

@mixin placeholder-content(
  $block-name,
  $show-menu-hint: false,
  $show-in-non-empty-doc: false
) {
  .is-empty-prose-doc {
    @if $show-menu-hint {
      content: $block-display-name ' (Click icon or TAB to change)';
    } @else {
      content: $block-display-name;
    }
  }
}

@mixin placeholder-content(
  $block-type,
  $block-name,
  $show-menu-hint: false,
  $show-in-non-empty-doc: false
) {
  @if $show-in-non-empty-doc {
    .o-#{$block-type} {
      --empty-block-placeholder: '#{$block-name}';
      @if $show-menu-hint {
        --empty-block-hint: ' (Click icon or TAB to change)';
      }
    }
  } @else {
    .is-empty-prose-doc .o-#{$block-type} {
      --empty-block-placeholder: '#{$block-name}';
      @if $show-menu-hint {
        --empty-block-hint: ' (Click icon or TAB to change)';
      }
    }
  }
}

@media screen {
  @include placeholder-content('general', 'General Text', false, false);
  @include placeholder-content('new-act', 'New Act', false, true);
  @include placeholder-content('end-of-act', 'End of Act', false, true);
  @include placeholder-content('bracket', '[Bracket]');
  @include placeholder-content('character', 'Speaker', true, false);
  @include placeholder-content('parenthetical', '(Parenthetical)');
  @include placeholder-content('dialogue', 'Dialogue', true, false);
  @include placeholder-content('transition', 'Transition');
  @include placeholder-content('scene', 'Scene Heading', true, true);
  @include placeholder-content('action', 'Action');
  @include placeholder-content('slug', 'Slug', false, true);

  // SHARED styles for all placeholders
  /* stylelint-disable-next-line selector-class-pattern */
  .o-block-element:has(.ProseMirror-trailingBreak:first-child) {
    // set color on the parent element so that underlines get picked up, etc.
    color: #aaa;
    display: block;

    &::before {
      color: #aaa;
      // this gets set per block type and per situation
      content: var(--empty-block-placeholder, '') var(--empty-block-hint, '');
      left: 0;
      position: absolute;
      right: 0;
      text-decoration: inherit;
    }
  }
}
