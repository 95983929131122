// THE EDITOR, ITSELF //

// the variables for the page width and right/left gutters
// are set based on the page layout.
.c-editor {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: var(--page-width);
}

.c-editor__gutterleft {
  margin-right: calc(-1 * var(--page-left-padding));
  position: relative;
  width: var(--page-left-padding);
}

.c-editor__overlay {
  bottom: 0;
  left: var(--page-left-padding);
  pointer-events: none;
  position: absolute;
  right: var(--page-right-padding);
  top: 0;
  z-index: map-get($zindex, 'editor-ui');

  * {
    pointer-events: auto;
  }
}

.c-editor__gutterright {
  margin-left: calc(-1 * var(--page-right-padding));
  pointer-events: none;
  position: relative;
  width: var(--page-right-padding);
}
