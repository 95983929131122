// plain badges

.o-badge {
  align-items: center;
  background-color: $d3-purple;
  border-radius: 3px;
  color: $light-white;
  display: flex;
  font-size: 11px;
  font-weight: bold;
  padding: 2px 4px;
  text-transform: uppercase;
  white-space: nowrap;

  &.o-badge--gray {
    background-color: $base-blue-gray;
  }

  &.o-badge--green {
    background-color: $success-color;
  }
}
