
          @use '@scss/all' as wallaby;
        
.cardWrapper {
  background-color: #fff;
}

// panel threads and feed comments (open) are clickable
.cardWrapper___panelThread,
.cardWrapper___feedComment {
  cursor: pointer;
}

// panel threads are dim by default (passive)
.cardWrapper___panelThread {
  --comment-action-button-opacity: 0;
  --thread-divider-opacity: 0.5;
  background-color: #f7f7f7;

  &:hover,
  &:focus {
    --comment-action-button-opacity: 1;
    --thread-divider-opacity: 0.8;
    background-color: #f2f2f2;
    box-shadow: 0 0 10px rgb(171 171 171 / 0.25);
  }
}

.cardWrapper___selected {
  --comment-action-button-opacity: 1;
  --thread-divider-opacity: 0.8;
  background-color: #fff;
  box-shadow: 0 0 20px rgb(171 171 171 / 0.45);

  &:hover,
  &:focus {
    background-color: #fff;
    box-shadow: 0 0 20px rgb(171 171 171 / 0.45);
  }
}

// purple outline around clickable feed comments
// on hover
.cardWrapper___feedComment:hover,
.cardWrapper___feedComment:focus {
  outline: 2px solid wallaby.$theme-violet-6;
}
