
          @use '@scss/all' as wallaby;
        
.prefModal_tab {
  font-size: 16px;
  padding: 5px 15px;
}

.prefModal_group {
  // --mantine-breakpoint-sm -1px (not directly usable in media queries)
  // https://mantine.dev/styles/responsive/#breakpoints-variables-in-css-modules
  @media screen and (max-width: 767px) {
    flex-direction: column; // this makes the left edge of the column flush
    gap: 40px;
  }
}

.prefModal_group___selects {
  @media screen and (max-width: 850px) {
    flex-direction: column; // this makes the left edge of the column flush
    gap: 40px;
  }
}

.prefModal_scrollingContent {
  max-height: 60vh;
  overflow-y: auto;
}

.prefModal_checkStack {
  @media screen and (max-width: 850px) {
    margin: 0 auto; // this centers the checkboxes without breaking their indentation
    width: 400px;
  }
}
