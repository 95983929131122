
          @use '@scss/all' as wallaby;
        
.copyToClipboardButton {
  background-color: wallaby.$theme-gray-1;
  position: absolute;
  right: 8px;
  top: 8px;
}

.code {
  font-family: 'Courier Prime', Courier, monospace;
}

.textArea {
  flex: 1;
}

.textAreaInput {
  font-family: 'Courier Prime', Courier, monospace;
  height: 20vh;
}
