// MODULAR FLEX BOXES

.l-box {
  // blanket flex container for modifiers to be added to
  display: flex;
  // I think this is a case where `!important` is okay
  // it's the most reliable way to override the layout information that's currently nested several layers deep in styled components

  // FLEX DIRECTIONS
  &.l-box--column {
    flex-direction: column;
  }

  // JUSTIFY

  &.l-box--center {
    justify-content: center;
  }

  // ALIGN ITEMS

  &.l-box--center-items {
    align-items: center;
  }
}

// 2019 PROTO-BEM LAYOUT 🛑
// layout and `l-` prefix experiments from when we were first figuring out BEM in 2019
// proceed with caution

.wrapper {
  float: none;
  margin: 0 auto;
  max-width: 1100px;

  @media screen and (max-width: 980px) {
    margin: 0 20px;
  }
}
