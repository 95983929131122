
          @use '@scss/all' as wallaby;
        
.statusBar {
  background-color: wallaby.$light-white;
  border-top: 1px solid wallaby.$theme-gray-4;
  flex: 0 0 36px;
  padding: 0 8px;
  width: 100%;
  z-index: 1;
}
