
          @use '@scss/all' as wallaby;
        
.elementCustom_colorInputWrapper {
  align-items: center;
  background-color: #fff;
  border: 1px solid wallaby.$theme-gray-3;
  border-radius: 3px;
  display: flex;
  gap: 5px;
  padding: 0 10px 0 6px;
}

.elementCustom_colorInput {
  border: 0;
  height: 33px;
  min-height: 33px;
  padding: 0;
  width: 80px;
}

.colorPicker_closeButton {
  position: absolute;
  right: 5px;
  top: 5px;
}

.colorPicker_button[data-variant='outline'] {
  border: 1px solid wallaby.$theme-dark-9;
  color: wallaby.$theme-dark-9;
  height: 33px;
  min-width: unset;
  padding: 6px 8px;

  &:disabled[data-disabled='true'] {
    border: 1px solid wallaby.$theme-gray-3;
    color: wallaby.$theme-gray-3;
  }
}
