
          @use '@scss/all' as wallaby;
        
// this is not a Sass module because we need to pass the styles along to PrinceXML for PDF generation too
// we can't rely on ambient styles because it is compiled for print all by itself
@use '../../../../scss/all' as *;

:root {
  --font-size-small: 15px;
}

@mixin truncate {
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media screen {
  .sbs-diff_left-select .sbs-diff_column___right {
    user-select: none;
  }

  .sbs-diff_right-select .sbs-diff_column___left {
    user-select: none;
  }

  .sbs-diff_hide-left .sbs-diff_column___left {
    opacity: 0;
  }

  .sbs-diff_hide-right .sbs-diff_column___right {
    opacity: 0;
  }

  .sbs-diff_slene {
    @include truncate;
    background-color: $theme-violet-0;
    border-radius: 3px;
    color: $theme-violet-5;
    padding: 5px 10px;
  }

  .sbs-diff_column___left {
    margin-right: 10px;
  }
}

.sbs-diff {
  height: 100%;
  max-width: 17in;
  overflow: auto;
  padding: 15px;
  width: calc(100%);
}

.sbs-diff_row {
  display: flex;
}

.sbs-diff_column {
  background-color: #fff;
  flex: 1;
  font-family: var(--script-font-family);
  line-height: 1.3;
  padding: 10px 20px;
  word-break: break-word;
}
// header

.sbs-diff_column___header {
  @include truncate;
  align-items: center;
  background-color: $theme-gray-0;
  border-bottom: 1px $theme-gray-2 solid;
  display: flex;
  flex-wrap: nowrap;
  font-family: var(--mantine-font-family);
  font-size: var(--font-size-small);
  height: 40px;
  padding: 0 10px;
}

.sbs-diff_titlerow {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  overflow: hidden;
}

.sbs-diff_titlecell {
  flex-basis: 50%;
  height: 40px;
  overflow: hidden;
  position: relative;
}

.sbs-diff_title__emphasized {
  font-weight: 700;
}

.sbs-diff_subtitle {
  color: $theme-gray-6;
  flex-shrink: 0;
}

// left side

.sbs-diff_change___left {
  background-color: $deletion-diff-light;
  border-bottom: 2px $deletion-diff-dark dashed;
}

// right side

.sbs-diff_column___right {
  margin-left: 10px;
}

.sbs-diff_change___right {
  background-color: $addition-diff-light;
  border-bottom: 2px $addition-diff-dark solid;
}

// omission break

.sbs-diff_omission {
  background-color: transparent;
  color: $theme-gray-6;
  font-family: var(--mantine-font-family);
  padding: 15px 0;
  text-align: center;
}

.sbs-diff_linebreak {
  padding: 0 20px;
}

// monochrome

.sbs-diff.sbs-diff___monochrome .sbs-diff_change___left {
  background-color: transparent;
  border-bottom: 0;
  text-decoration: line-through;
}

.sbs-diff.sbs-diff___monochrome .sbs-diff_change___right {
  background-color: transparent;
  border-bottom: 0;
  text-decoration-color: #000;
  text-decoration-line: underline;
  text-decoration-style: double;
  text-decoration-thickness: 1px;
  text-underline-offset: 6px;
}

.sbs-diff_placeholder {
  background-color: $theme-gray-0;
  border: 1px dashed $theme-gray-5;
  border-radius: 5px;
  inset: 0;
  position: absolute;
}
