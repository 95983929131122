// LAYOUT HELPERS

// COLOR HELPERS

.is-highlighted {
  background-color: $highlight-yellow;
}

// TEXT HELPERS

// font families

.is-script-text {
  font-family: $script-font-family;
  font-size: $script-font-size;
  line-height: $script-line-height;
}

// text colors

.is-light {
  color: $d1-mid-gray;
}
