
          @use '@scss/all' as wallaby;
        
.elementMenu {
  align-items: center;
  background-color: wallaby.$light-white;
  border-radius: 5px;
  color: wallaby.$theme-gray-5;
  // in some circumstances --element-menu-display is set to 'none'
  // by other components (e.g. in the PrintPreviewWrapper when editing
  // element numbers. If not set, use flex to display
  display: var(--element-menu-display, flex);
  height: 32px;
  justify-content: center;
  left: var(--page-left-padding);
  margin-top: -8px;
  pointer-events: var(--element-menu-pointer-events, unset);
  position: absolute;
  // the left position, puts this inside the blocks. We
  // want it just outside, so shift it left by its width
  transform: translateX(-100%);
  width: 38px;
  // draw above avatars and element numbers if needed
  z-index: 11;

  &.pageless {
    height: 22px;
    left: 18px;
    margin-top: -2px;
    right: unset;
    transform: none;
    width: 22px;
  }

  &:hover {
    background-color: wallaby.$theme-violet-5;
    color: wallaby.$theme-gray-2;
  }
}

.elementMenu_icon {
  align-items: center;
  display: flex;
  justify-content: center;
}
