
          @use '@scss/all' as wallaby;
        
.root {
  border-radius: 3px;
  font-weight: 400;

  &[data-size='sm'] {
    font-size: 15px;
    height: 31px;
    min-width: 80px;
    padding-left: 12px;
    padding-right: 12px;
  }

  &[data-variant='danger'] {
    background-color: unset;
    border: 1px var(--mantine-color-red-6) solid;
    color: var(--mantine-color-red-6);

    &:hover {
      background-color: var(--mantine-color-red-0);
    }
  }
}

.root:disabled {
  &[data-disabled='true'] {
    background-color: var(--mantine-color-violet-6);
    color: #fff;
    cursor: not-allowed;
    opacity: 0.6;

    &[data-variant='danger'] {
      background-color: #fff;
      border: 1px var(--mantine-color-red-6) solid;
      color: var(--mantine-color-red-6);
    }

    &[data-variant='outline'] {
      background-color: #fff;
      border: 1px var(--mantine-color-violet-9) solid;
      color: var(--mantine-color-violet-9);
    }

    &[data-variant='light'] {
      background-color: var(--mantine-color-violet-0);
      color: var(--mantine-color-violet-9);
    }

    &[data-variant='subtle'] {
      background-color: unset;
      color: var(--mantine-color-violet-9);
    }

    &[data-variant='filled'] {
      background-color: var(--mantine-color-violet-6);
      color: #fff;
    }
  }
}
