//
// UTILITIES AND MODIFIERS //
//

// text colors and weights

.is-light {
  color: $d1-mid-gray;
}

// text size

.is-small {
  font-size: $s1-font-size;
}
