/*
these styles exist to smooth over weirdness between conflicting legacy css and new modual scss styles.
it shouldn't exist forever.
once scss is fully implemented, we should delete this.
*/

/*
each entry should have an explanation for why it's needed.
*/

.o-dropdown {
  &.is-open {
    .o-dropdown__list {
      .o-dropdownlist__item {
        // override's skeleton's li's 1rem margin-bottom
        margin-bottom: 0;
      }
    }
  }
}

/*
FORMS
*/

form {
  // centering buttons without centering form fields
  display: flex;
  flex-direction: column;
  // skeleton override
  margin-bottom: 0;

  // don't override any mantine input (checkbox, text, etc.)
  // stylelint-disable-next-line selector-class-pattern
  input:not([class*='mantine-']) {
    margin-top: 5px;
  }
}
