
          @use '@scss/all' as wallaby;
        
// value is set in component
$gutter-avatar-size: var(--gutter-avatar-size);

// vertical centering
$margin-top: calc(0.5em - $gutter-avatar-size / 2);

$horizontal-centering: calc(-1 * $gutter-avatar-size - 5px);

.avatar {
  margin-top: $margin-top;
  position: absolute;
  z-index: 2;

  &.paged {
    left: $horizontal-centering;
  }

  &.pageless {
    left: $gutter-avatar-size;
  }
}

.animate {
  transition: top 0.25s ease-in-out;
}

.invisible {
  opacity: 0;
  pointer-events: none;
}
