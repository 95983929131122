// LEGACY HOVER TOOLTIP

.o-hovertooltip[data-tooltip] {
  &::after {
    // centers tooltip under button because the button it lives in displays as a flex column
    animation-delay: 0.4s;
    animation-duration: 0s;
    animation-fill-mode: forwards;
    animation-name: delay-appearance;
    background-color: $d1-dark-gray;
    border-radius: 3px;
    box-shadow: 0 0 4px rgba(190, 190, 190, 0.3);
    color: $light-white;
    content: attr(data-tooltip);
    display: none;
    font-family: $base-font-family;
    font-size: $base-font-size;
    letter-spacing: normal;
    opacity: 0;
    padding: 5px 15px 7px;
    position: absolute;
    text-align: center;
    text-transform: none;
    top: 45px;
    white-space: nowrap;
    z-index: map-get($zindex, 'tooltip');
  }

  &:hover::after {
    display: block;
  }

  &.o-hovertooltip--small {
    &::after {
      font-size: $s2-font-size;
      padding: 4px 8px 5px;
    }
  }

  &.o-hovertooltip--dash {
    &::after {
      top: 20px;

      @media screen and (max-width: 600px) {
        align-self: flex-end;
      }
    }
  }

  &.o-hovertooltip--settings {
    &::after {
      top: 20px;

      @media screen and (max-width: 600px) {
        align-self: flex-end;
      }
    }
  }
}
