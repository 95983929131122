
          @use '@scss/all' as wallaby;
        
$border-color: #aaa;
$darker-border-color: #888;
$print-border-style: 1.5px $border-color solid;

.printable-rundown-grid .ag-theme-alpine {
  // doesn't work to use variable to set ag vars
  --ag-row-border-color: #aaa;
  --ag-cell-horizontal-border: #aaa solid;

  .ag-header {
    background: transparent;
  }

  .ag-cell {
    white-space: normal;
  }

  .ag-row {
    background: none;
    border-left: $print-border-style;
    border-right: $print-border-style;
  }

  .ag-row-hover::before {
    background-color: transparent;
  }

  .ag-root-wrapper {
    border: 0 !important;
    margin: 0;
    overflow: visible;
    padding: 0;
    top: 0;
  }

  .ag-cell-focus {
    border-color: transparent !important;
    border-right-color: $border-color !important;
  }

  .ag-header-cell-resize::after {
    background-color: #8e66c8;
    height: 50%;
    top: unset;
  }

  .ag-cell-wrap-text {
    -prince-linebreak-magic: none;
    white-space: pre-wrap;
  }
}

@media screen {
  .printable-rundown-grid .ag-theme-alpine {
    .ag-root-wrapper {
      background-color: transparent;
    }

    .ag-center-cols-container {
      background-color: #fff;
      border-top: $print-border-style;
      overflow: hidden;
    }

    .ag-header-row {
      background-color: #fff;
      margin-left: 2px;
    }
  }
}

@media print {
  section {
    .ag-header-row {
      border: $print-border-style;
      border-bottom-width: 4px;
      border-color: $darker-border-color;
      margin-top: 2px;
    }

    .ag-header-cell {
      font-weight: bold;
    }

    .ag-header-cell:not(:last-of-type) {
      border-right: 1px $border-color solid !important;
    }
  }
}
