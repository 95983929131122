
          @use '@scss/all' as wallaby;
        
.snapshotCard {
  background-color: #f7f7f7;
  border: 2px solid transparent;
  flex: 1;
  padding: 5px 5px 5px 8px;
}

.snapshotCard.active {
  background-color: #fff;
}

.snapshotCard.sticky {
  background-color: #fff;
  border-color: wallaby.$theme-gray-3;
  border-width: 1px;
  flex: unset;
  justify-content: center;
}

.snapshotCard.clickable {
  cursor: pointer;

  &:hover {
    background-color: #f2f2f2;
  }
}

.chooseSnapshotCard {
  background-color: wallaby.$theme-gray-0;
  border: 1px dashed wallaby.$theme-gray-5;
  border-radius: 3px;
  color: wallaby.$theme-gray-6;
  justify-content: center;
}

.pickSideButton {
  position: absolute;
  right: 40px;
  top: 50%;
  transform: translateY(-50%);
}

.snapshotCard:not(:hover) .pickSideButton {
  display: none;
}

.cardContent {
  flex: 1;
}

// hide the hover variant of the edit button
// if not hovering.
.snapshotCard.editButtonHover:not(:hover) .editButton {
  display: none;
}

// hide the edit button if editing
.snapshotCard.isEditing .editButton {
  display: none;
}

.editIcon {
  color: wallaby.$theme-gray-5;
}

// darken the edit button when hovering over it directy
.editButton:hover .editIcon {
  color: wallaby.$theme-dark-5;
}
