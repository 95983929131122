
          @use '@scss/all' as wallaby;
        
.helpMenu_link {
  align-items: center;
  color: var(--mantine-color-dark-9);
  display: flex;
  text-decoration: none;

  &:hover,
  &:focus {
    color: var(--mantine-color-dark-9);
    text-decoration: none;
  }
}
