
          @use '@scss/all' as wallaby;
        
// tabs

.colorPicker_tabLabel {
  align-items: center;
  display: flex;
}

.colorPicker_tabList {
  justify-content: center;
}

@mixin colorpickericon {
  font-size: 14px;
  margin-right: 5px;
  padding: 2px 5px;
}

.colorPicker_tabIcon {
  @include colorpickericon;
}

.colorPicker_tabIcon___highlight {
  @include colorpickericon;
  background-color: wallaby.$theme-dark-9 !important;
  border-radius: 3px;
}

.colorPicker_closeBtn {
  position: absolute;
  right: 10px;
  top: 10px;
}

.colorPicker_badge {
  position: absolute;
  right: 40px;
  top: 15px;
}
