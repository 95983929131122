
          @use '@scss/all' as wallaby;
        
.styleBox {
  background-color: #fff;
  border: 1px solid wallaby.$theme-gray-3;
  border-radius: 3px;
  font-family: 'Courier New', Courier, monospace;
  padding: 7px;
  width: 160px;
}

.cb_bold,
.cb_italic,
.cb_underline,
.cb_caps {
  border-radius: 3px;
  padding: 3px;
}

.cb_bold {
  font-weight: bold;
}

.cb_italic {
  font-style: italic;
}

.cb_underline {
  text-decoration: underline;
}

.cb_caps {
  text-transform: uppercase;
}
