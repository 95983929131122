
          @use '@scss/all' as wallaby;
        
.archivedScript_scroller {
  flex: 1;
  overflow-y: auto;
  padding: 25px;
}

.archivedScript_content {
  padding: 10px;
}

.archivedScript_linkedLine {
  margin-bottom: 10px;
}

.archivedScript_anchor {
  word-break: break-all;
}
