
          @use '@scss/all' as wallaby;
        
:root {
  --zoom-level: 1;
}

.scriptWrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  width: 100%;
}

// if we're editing an element number, set a var to suppress
// the element menu
.scriptWrapper:has(:global(.element-number-input:focus-within)) {
  --element-menu-display: none;
}

// when selecting text, prevent the element menu from interfering
// with drag selection (used in InlineElementMenu.module.scss)
.scriptWrapper:has(:global(.o-block-element:active)) {
  --element-menu-pointer-events: none;
}

.scriptAndComments {
  display: flex;
  flex: 1;
  flex-direction: row;
  height: 100%;
  overflow: hidden;
  width: 100%;
}
