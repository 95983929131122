
          @use '@scss/all' as wallaby;
        
.option {
  font-size: 16px;

  &[data-checked='true'] {
    background: var(--mantine-color-violet-6);
    color: var(--mantine-color-white);
  }
}
