// links

/*
TODO: declare fewer styles on <a> tags altogether
it'll require moving some cheese around
*/
a {
  color: $d3-purple;
  text-decoration: none;

  &:hover {
    color: $d3-purple; // skeleton override
    text-decoration: underline;
  }
}

.is-link-icon {
  font-size: $s2-font-size;
  margin-right: 5px;
}

.is-plain-link {
  color: $d2-dark-gray;

  &:hover {
    color: $d2-dark-gray;
    text-decoration: none;
  }
}
