
          @use '@scss/all' as wallaby;
        
.folderListing {
  display: flex;
  height: 100%;
  justify-content: stretch;
}

.folderListing_contents {
  align-items: center;
  display: flex;
  gap: 10px;
  height: 100%;
}

.folderNameAndIcon {
  align-items: center;
  border-radius: 3px;
  display: flex;
  flex: 1;

  height: 100%;
  overflow: hidden;
  padding: 0 5px;

  &.disabled {
    color: wallaby.$d3-light-gray;
    pointer-events: none;
  }

  &.selectable {
    @include wallaby.listing-background(selected);
  }

  &:not(.selected) {
    // besides hover, we also want a gray background if one of the menus is open
    // in the latest browsers anyway https://developer.mozilla.org/en-US/docs/Web/CSS/:has
    &:has(> .menuWrapper > *[data-expanded]) {
      background-color: wallaby.$theme-gray-1;
    }

    // hide the ... menu unless the folder is selected or the user is hovering
    &:not(:hover) .menuWrapper > *:not([data-expanded]) {
      opacity: 0;
    }
  }
}

.folderName {
  flex: 1;
  font-family: 'Lato', sans-serif;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.noSubfolders {
  margin-top: -5px;
  overflow: hidden;
  padding-left: 14px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
