
          @use '@scss/all' as wallaby;
        
.bodyPlaceholder {
  border: 1px dashed wallaby.$theme-gray-6;
  border-radius: 5px;
}

.bodyPlaceholder.error {
  border-color: wallaby.$theme-red-6;
}

.bodyPlaceholder:not(.fillParent) {
  margin: 30px 20px;
  max-width: 8.5in;
  padding: 50px 0;
  width: 100%;
}

.bodyPlaceholder.fillParent {
  inset: 0;
  position: absolute;
}
