
          @use '@scss/all' as wallaby;
        
.miniStepper_step {
  background-color: wallaby.$theme-violet-0;
  border-radius: 50%;
  height: 15px;
  transition: background-color 150ms ease;
  width: 15px;

  &[aria-selected='true'] {
    background-color: wallaby.$theme-violet-4;
  }

  &:focus-visible {
    outline: 2px solid wallaby.$theme-violet-4;
    outline-offset: 2px;
  }
}
