
          @use '@scss/all' as wallaby;
        
.colorTextInput {
  width: 120px;
}

.colorTextInput_hexPreview {
  box-shadow:
    rgba(0, 0, 0, 0.1) 0 0 0 1px inset,
    rgb(0, 0, 0, 0.1) 0 0 1px inset;
}
