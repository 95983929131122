
          @use '@scss/all' as wallaby;
        
.scriptChecklist_scroll {
  border: 1px solid wallaby.$theme-gray-4;
  border-radius: 0 0 3px 3px;
  max-height: 25vh;
  overflow-y: auto;
  padding: 0 10px;
}

.scriptChecklist_selectAll {
  background-color: wallaby.$theme-gray-1;
  border: 1px solid wallaby.$theme-gray-4;
  border-bottom: 0;
  padding: 5px 10px;
}
