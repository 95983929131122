
          @use '@scss/all' as wallaby;
        
.documentOutline {
  display: flex;
  flex-direction: column;
  font-family: wallaby.$script-font-family;
  font-size: wallaby.$s1-font-size;
  height: 100%;
  justify-content: space-between;
  left: 0;
  overflow: hidden;
  overscroll-behavior: contain; /* safari ignores */
  position: relative;
  top: 0;
  z-index: map-get(wallaby.$zindex, 'editor-menu');
}

.documentOutline_scroll {
  flex: 1;
  overflow-y: auto;
  padding: 10px 0;
}

.documentOutline_footer {
  border-top: 1px solid wallaby.$theme-gray-3;
  font-family: wallaby.$base-font-family;
  padding: 8px 5px;
  white-space: nowrap;
}
